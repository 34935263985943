<template>

  <div>
    <div id="sesion_activa" style="display:none;">
      <b-navbar id="navbar" toggleable="md" type="dark" variant="danger">
        <b-navbar-brand class="d-md-none">
          <img class="navbar-logo" src="../assets/logo_budweiser.png" alt="Tryvium Logo" height="50px">
        </b-navbar-brand>

        <b-navbar-toggle target="collapse-area"></b-navbar-toggle>

        <b-collapse id="collapse-area" is-nav>

          <b-navbar-nav class="mx-auto">
            <b-nav-item href="#" class="px-md-4 menu_item">¿CÓMO JUGAR?</b-nav-item>
            <b-nav-item href="#" class="px-md-4 menu_item">PREMIOS</b-nav-item>
            <b-nav-item href="#" class="px-md-4 menu_item">CANJEA AQUÍ</b-nav-item>
          </b-navbar-nav>

          <b-navbar-nav class="d-none d-md-block mx-auto">
            <b-nav-text style="padding: 0px;">
              <img class="navbar-logo" src="../assets/logo_budweiser.png" alt="Tryvium Logo" height="50px">
            </b-nav-text>
          </b-navbar-nav>

          <b-navbar-nav class="mx-auto">
            <b-nav-item href="#" class="px-md-4 menu_item">APUESTA AQUÍ</b-nav-item>
            <b-nav-item href="#" class="px-md-4 menu_item">MIS APUESTAS</b-nav-item>
            <b-nav-item href="#" class="px-md-4 ml-auto">
              <b-nav-text style="padding: 0px;">
                <img src="../assets/bitcoin_referencial.png" class="d-inline-block align-top" alt="Kitten" height="30px" id="img_money">
                <div style="display: inline-block;" id="div_money">&nbsp; 0</div>
              </b-nav-text>

            </b-nav-item>
          </b-navbar-nav>

        </b-collapse>
      </b-navbar>
    </div>
    <div id="sesion_inactiva" style="color: white;">
      <br>
      <h1 id="h1_mensaje">Cargando ...</h1>
    </div>
  </div>
</template>

<script>

import $ from 'jquery'

import axios from 'axios'


function getParameterByName(name) {
    name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
    var regex = new RegExp("[\\?&]" + name + "=([^&#]*)"),
    results = regex.exec(location.search);
    return results === null ? "" : decodeURIComponent(results[1].replace(/\+/g, " "));
}
            var auth_data_plano=getParameterByName('auth_data');


            var auth_data = "";
            let document_number=0;
            var user_id = 0;//;
            var auth_token = "";//"";
            var connecting = false;
            var site_id = 279;
            var sws;
            // var ws_url = "wss://us-swarm-ws.betconstruct.com/";
            var ws_url = "wss://soe-swarm-apuesta.betconstruct.com/";
            var message_queue = [];
            var swsid;
            var ws_session = false;
            var fw_user = [];
            var fw_active = true;
            // var fw_active = false;
            var interval_fw_get_balance = false;
            var interval_sw_get_user_balance = false;
            var l_inf=0;



            $(function () {
              $("#sesion_activa").hide();
              if(parseInt(auth_data_plano.length)>0){
                  try {
                    auth_data = JSON.parse(getParameterByName('auth_data'));
                    user_id = auth_data.user_id;//346276305;
                    auth_token = auth_data.auth_token;//"D27DDB51F338FEBBC6DAEE73EF8A1830";
                    //console.log(auth_data);
                    //console.log(user_id);
                    //console.log(auth_token);
                    // Numerico && mayor a 0 && tocken existente
                    if((!isNaN(user_id))&&(parseInt(user_id)>0)&&(parseInt(auth_token.length)>0)){
                        ws_connect();
                    }else{
                      $('#h1_mensaje').html("Por favor inicie sesión.");
                    }
                  }
                  catch(err) {
                    $('#h1_mensaje').html("Por favor inicie sesión.");
                    //$('#img_money').after(' 0');
                    console.log("Error try-catch: "+err.message);
                  }
              }else{
                    $('#h1_mensaje').html("Por favor inicie sesión.");
                console.log("No se recupero el json del GET: "+auth_data_plano);
              }
            });
            function ws_has() {
                //console.log("ws_has");
                if (!("WebSocket" in window)) {
                    return false;
                } else {
                    return true;
                }
            }
            function ws_connect() {
                $(document).trigger("ws_connect", []);
                // console.log("ws_connect");
                $(".fw_status").html('Conectando con BC...');
                if (!connecting) {
                    connecting = true;
                    if (ws_has()) {
                        try {
                            //console.log(ws_url);
                            sws = new WebSocket(ws_url);
                            //console.log(sws);
                            sws.onopen = function () {
                                $(".fw_status").append(' Conectado!');
                                // console.log("Connected!");
                                sw_open_session();
                                connecting = false;
                            };
                            sws.onmessage = function (msg) {
                                // console.log("onmessage");
                                var obj = $.parseJSON(msg.data);
                                // console.log(swsid);
                                if (obj.rid && obj.rid === "sw_open_session") {
                                    $(document).trigger("sw_open_session", obj);
                                    // console.log("--------------> sw_open_session");
                                     //console.log(obj);
                                    swsid = obj.data.sid;
                                    sw_restore_login();
                                    // balance_history();
                                } else if (obj.rid === "restore_login") {
                                    $(document).trigger("restore_login", obj);
                                    // $(".fw_status").append('<br>Validando credenciales...');
                                     //console.log("--------------> restore_login");
                                    if (obj.code == 12) {
                                        $(document).trigger("sw_login_error", obj);
                                        //console.log("BAD LOGIN, BYE BYE");
                                        $('#h1_mensaje').html("Por favor inicie sesión.");
                                    } else {
                                         //console.log("LOGIN OK");
                                        // console.log(obj);
                                        // console.log(user_id);
                                        // console.log(auth_token);

                                        // $(".fw_status").html('Obteniendo usuario...');
                                        var next = false;
                                        if (obj.data.auth_token === auth_token) {
                                             //console.log("auth_token es igual");
                                            next = true;
                                        } else {
                                            //console.log("auth_token NO es igual");
                                            next = false;
                                            $('#h1_mensaje').html("Por favor inicie sesión.");
                                        }
                                        if (obj.data.user_id === user_id) {
                                            next = true;
                                            //console.log("user_id es igual");
                                        } else {
                                            //console.log("user_id NO es igual");
                                            next = false;
                                            $('#h1_mensaje').html("Por favor inicie sesión.");
                                        }
                                        if (next) {
                                            $(".fw_status").html('Obteniendo usuario...');
                                            sw_get_user_balance();
                                        } else {
                                            $(document).trigger("sw_login_error", obj);
                                            $('#h1_mensaje').html("Por favor inicie sesión.");
                                        }
                                    }
                                } else if (obj.rid === "sw_get_user_balance") {
                                    $(document).trigger("sw_get_user_balance", obj);

                                    //console.log(obj.data.doc_number);
                                    document_number= obj.data.doc_number;
                                    l_inf=1;
                                    //get_balance(document_number);
                                    infinito();

                                    // console.log("--------------> sw_get_user_balance");
                                    // console.log(obj);
                                    if (obj.code == 12) {
                                        // console.log("BAD LOGIN sw_get_user_balance, BYE BYE");
                                        // $(document).trigger("clearBetData");
                                        $(document).trigger("sw_login_error", obj);
                                            $('#h1_mensaje').html("Por favor inicie sesión.");
                                        // $(document).trigger("invalidSession", [data]);
                                    } else {
                                        // console.log("LOGIN sw_get_user_balance OK");
                                        $(document).trigger("sw_login_ok", obj);
                                        $(document).trigger("sw_get_user_balance_event", obj);
                                        // return "sw_login_ok";
                                        // $(document).trigger("updatesw_get_user_balance", [data.data]);
                                    }
                                }
                                // console.log(swsid);
                            };
                            sws.onclose = function () {
                                $(document).trigger("ws_onclose", []);
                                // swsid ='';
                                connecting = false;
                                 //console.log('Disconnected. Socket Status: '+sws.readyState+' (Closed)');
                                 $('#h1_mensaje').html("Por favor inicie sesión.");
                            };
                        } catch (exception) {
                            $(document).trigger("ws_error", exception);
                             //console.log("Disconnected!");
                            connecting = false;
                            $('#h1_mensaje').html("Por favor inicie sesión.");
                        }
                    } else {
                        $(document).trigger("ws_nows", []);
                        //console.log("NO WS, BYE");
                        $('#h1_mensaje').html("Por favor inicie sesión.");
                    }
                }
            }
            function sw_restore_login() {
                $(".fw_status").html('Validando credenciales...');
                // console.log("sw_restore_login");
                if (typeof user_id != 'undefined' && user_id && typeof auth_token != 'undefined' && auth_token) {
                    var _msg = '{"command": "restore_login","rid":"restore_login","params": {"user_id": ' + user_id + ',"auth_token": "' + auth_token + '"}}';
                    sw_queue_msg(_msg);
                }
            }
            function sw_get_user_balance() {
                if (!fw_active) {
                    return false;
                }
                // $(".fw_status").append('<br>Obteniendo usuario...');
                // console.log("sw_get_user_balance");
                var _msg = '{"command":"get_user", "rid":"sw_get_user_balance"}';
                sw_queue_msg(_msg);
                return "sw_login_ok";
            }
            function sw_open_session() {
                $(".fw_status").html('Creando sesión...');
                sw_send_msg('{"command":"request_session","rid":"sw_open_session","params":{"site_id": ' + site_id + ',"language":"spa"}}')
            }
            function sw_send_msg(msg) {
                if (sws && sws.readyState == 1) {
                    sws.send(msg);
                }
            }
            function sw_queue_msg(msg) {
                if (sws && sws.readyState == 1 && swsid) {
                    sw_send_msg(msg);
                } else {
                    message_queue.push(msg);
                    ws_connect();
                }
            }
            function get_balance(document_number_2) {
              //console.log('get bsl');
              $.get('https://api-app.apuestatotal.dev/v1/client/balance?document_number='+document_number_2)
                      .done(function (data) {
                        //console.log('exito');
                        var balance_total=data.result.client_balances[0].totalBalance;
                        //console.log(balance_total);
                        //$('#img_money').after(' '+balance_total);
                        $('#div_money').html('&nbsp;'+balance_total);
                        $("#sesion_inactiva").hide();
                        $("#sesion_activa").show();
                      });
            }
            function infinito() {
                //console.log("Hi");
                get_balance(document_number);
                if(parseInt(l_inf)>0){
                  //setTimeout(infinito(), 5000);
                  setTimeout(function(){ infinito(); }, 10000);
                }
            }



export default {
  name:"NavBar",
}

</script>

<style>
/*#navbar {
  .navbar-logo {
    width: 50px;
  }
}*/
.bg-danger {
    background-color: #83001C!important;
}
.menu_item a{
    size: 20px ;
    color: white !important;
}

</style>